<template>
  <div id="get-paid">
    <Navbar class="white-text white-logo light-menu-icon" />

    <div class="background">
      <img src="../assets/wave-footer.png" alt="footer background" />
    </div>

    <section>
      <h1>Get Paid</h1>
      <h2>Publishers</h2>

      <div class="cards">
        <div class="card">
          <h3>Add Your Songs &<br />Collect Royalties</h3>
          <p>
            Add your songs for free to the database used by the largest digital
            music services to license and pay music publishers.
          </p>
          <router-link
            :to="{ name: 'register-songs' }"
            class="button primary shadow"
            >REGISTER MY SONGS</router-link
          >
        </div>
        <div class="card">
          <h3>Take Your Royalties to the Next Level</h3>
          <p>
            <router-link :to="{ name: 'hfa-affiliate' }">
              <strong>Become an HFA Affiliate</strong> </router-link
            >, and we will handle your U.S. mechanical licensing and worldwide
            royalty collections, data management, and YouTube™ monetization.
          </p>
          <router-link
            :to="{ name: 'hfa-affiliate' }"
            class="button primary shadow"
            >LEARN MORE</router-link
          >
        </div>
      </div>
    </section>
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "GetPaid",
  components: {
    Navbar,
    Footer
  },
  metaInfo: function() {
    return {
      title: " - Become an HFA Affiliate",
      meta: [
        {
          name: "description",
          content:
            "Join a team of world class experts in every area of mechanical licensing, tracking, reporting and payments."
        }
      ],
      link: [
        {
          vmid: "canonical",
          rel: "canonical",
          href: this.$store.getters["canonical"]()
        }
      ]
    };
  }
};
</script>

<style lang="scss">
#get-paid {
  color: #fff;

  #footer {
    margin-top: 30vh;
  }

  > .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    z-index: -1;

    img {
      width: 400vw;
      height: 80vh;
      transform: scale(1, -1) translate(-10%, 0%);
    }
  }
  section {
    height: 110vh;

    .button {
      font-size: 1.375em;
      padding: 0.8em 0;
      width: 220px;
      display: inline-block;
      margin-top: 2.3em;
    }
  }

  h1,
  h2 {
    color: #fff;
    text-align: center;
  }
  h1 {
    font-family: "Antic Slab", serif;
    font-size: 1.2em;
    font-weight: 400;
    margin: 0;
  }
  h2 {
    margin: 0;
    font-family: "Fjalla One", sans-serif;
    font-size: 1.75em;
    font-weight: 400;
  }
  .cards {
    width: 100%;
    margin: 20px auto 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .card {
      text-align: center;
      width: calc(100% - 4em);
      padding: 2em;
      font-size: 0.8em;

      &:first-child {
        border-radius: 8px 8px 0 0;
        background-color: #12399f;
      }
      &:last-child {
        border-radius: 0 0 8px 8px;
        background-color: #60c1cc;
      }

      h3 {
        font-size: 1.75em;
        font-family: "Fjalla One", sans-serif;
        font-weight: 400;
        margin: 0 0 1.6em;
      }
      p {
        font-size: 1.375em;
        line-height: 1.55;

        a {
          color: #fff;
          text-decoration: underline;
        }
      }
    }
  }

  @media (min-width: $desktop) {
    #footer {
      margin-top: 0;
    }

    > .background {
      img {
        width: 100vw;
        height: 50vh;
        transform: scale(1, -1);
      }
    }

    h1 {
      font-size: 2em;
      margin: 0.8em 0;
    }
    h2 {
      font-size: 2.375em;
      margin: 0.8em 0;
    }
    .cards {
      width: 65.89vw;
      margin: 100px auto 0;
      flex-direction: row;

      .card {
        font-size: 1em;
        width: 50%;
        padding: 4.395vw 2.199vw;

        &:first-child,
        &:last-child {
          border-radius: 8px;
        }
      }
    }
  }
}
</style>
